






































































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import PersonFilterDTO from "@/dto/person/PersonFilterDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RegexUtils from "@/utils/RegexUtils";
import RouteNames from "@/router/RouteNames";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import {fromPerson, WorkspaceType, WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import PersonCreationComponent from "@/components/profile/admin/person/PersonCreationComponent.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import DocumentUtils from "@/utils/DocumentUtils"
import PortalInput from "@/components/common/PortalInput.vue";
import {InputType} from "@/components/common/InputType";
import {ifValid, validate} from "@/utils/ComponentUtils";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import Clipboard from "@/state/Clipboard";
import Notifications from "@/state/Notifications";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  computed: {
    InputType() {
      return InputType
    },
    WorkspaceTypePathElement() {
      return WorkspaceTypePathElement
    }
  },
  components: {PortalSelect, PortalCheckbox, PortalInput, PaginationComponent}
})
export default class PersonList extends Vue {
  private RouteNames = RouteNames;

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  private filter = new PersonFilterDTO();

  private persons: Array<PersonDTO> = [];

  private totalPages = 1;

  private message = "";

  private successful = true;

  $refs!: {
    searchButton: HTMLButtonElement
  }

  copy(text: string) {
    Clipboard.write(text);
  }

  private buildOptions(): Array<SelectOption> {
    return [
      SelectOption.builder().title("All").value(null).build(),
      SelectOption.builder().title("Only clients").value(true).build(),
      SelectOption.builder().title("Only NOT clients").value(false).build()
    ]
  }

  public mounted(): void {
    document.addEventListener('keydown', this.globalKeyEvent);
    this.filter.firstName = this.$route.query.firstName as string;
    this.filter.lastName = this.$route.query.lastName as string;
    this.filter.email = this.$route.query.email as string;
    this.filter.ssn = this.$route.query.ssn as string;
    console.log(this.filter.isClient)
    if (!this.hasAdministrativePrivileges) {
      this.$router.push({name: RouteNames.HOME});
    } else {
      this.loadPersons();
    }
  }

  public activated() {
    document.addEventListener('keydown', this.globalKeyEvent);
  }

  public deactivated() {
    document.removeEventListener('keydown', this.globalKeyEvent);
  }

  public beforeDestroy() {
    document.removeEventListener('keydown', this.globalKeyEvent);
  }

  globalKeyEvent (event: KeyboardEvent) {
    if (event.defaultPrevented) {
      return;
    }
    if (event.code === "Enter") {
      this.$refs.searchButton?.click();
    }
  }

  private handleSearch(): void {
    this.filter.pageNumber = 1;
    this.loadPersons();
  }

  private loadPersons(): void {
    validate(this).then(res => console.log(res))
    ifValid(this, () => {
      this.startLoading();
      this.message = "";
      PersonService.getByFilter(this.filter).then(
          (response) => {
            this.persons = response.data.data;
            this.totalPages = response.data.countOfPages;
            this.successful = true;
            this.stopLoading();
          },
          (error) => {
            this.successful = false;
            this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
            this.stopLoading();
          }
      ).then(() => {
        this.filter.onlyCustomers = true;
        this.applyRouteParams();
      })
    })
  }

  private applyRouteParams(): void {
    let dict: { [key: string]: string } = {};

    if (this.filter.firstName) {
      dict.firstName = this.filter.firstName;
    }

    if (this.filter.lastName) {
      dict.lastName = this.filter.lastName;
    }

    if (this.filter.email) {
      dict.email = this.filter.email as string;
    }

    if (this.filter.ssn) {
      dict.ssn = this.filter.ssn as string;
    }

    this.$router.replace({
      query: dict
    })
  }

  private registerNewUser(): void {
    this.$modal.show(
        PersonCreationComponent,
        {
          onSuccess: () => this.loadPersons()
        }
    );
  }

  private changePage(page: number): void {
    this.filter.pageNumber = page;
    this.loadPersons();
  }

  get getEmailSortIcon(): string {
    return this.getSortIcon("EMAIL");
  }

  get getFirstNameSortIcon(): string {
    return this.getSortIcon("FIRST_NAME");
  }

  get getLastNameSortIcon(): string {
    return this.getSortIcon("LAST_NAME");
  }

  private getSortIcon(field: string): string {
    if (this.filter.orderField !== field) {
      return "sort";
    } else if (this.filter.orderDirection === "ASC") {
      return "sort-up";
    } else {
      return "sort-down";
    }
  }

  private switchOrder(field: string): void {
    if (field === this.filter.orderField) {
      this.toggleDirection();
    } else {
      this.filter.orderField = field;
      this.filter.orderDirection = "ASC";
    }
    this.loadPersons();
  }

  private toggleDirection(): void {
    this.filter.orderDirection = this.filter.orderDirection === "ASC" ? "DESC" : "ASC";
  }

  private clearFilter(): void {
    this.filter = new PersonFilterDTO();
    this.loadPersons();
  }

  private exportToExcel(): void {
    this.startLoading();
    this.message = "";
    PersonService.exportToExcel(this.filter).then(
        (response) => {
          DocumentUtils.download(response.data.id)
          this.successful = true;
          this.stopLoading();
        },
        (error) => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

}
